// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require('bootstrap')
window.jQuery = $;
window.$ = $;
window.Rails = Rails;

$(document).ready(function() {
  $('#territory-popup-modal .submit-btn').on('click', function(e){
    var territory = $('#territory').val();
    var language = $('#language').val();
    window.location.href = window.location.href.replace( /[\?#].*|$/, "?territory=" + territory + "&language=" + language);
  });

  $('.territory-popup').on('click', function() {
    $('#territory-popup-modal').modal('show');
  });

  $('.change_language').on('click', function(e){
    e.preventDefault();
    e.stopPropagation();
    window.location.href = window.location.href.replace( /[\?#].*|$/, "?language=" + $(this).data().language.replace('#', ''));
  });

  $('.dashb-statistics').on('click', function(e){
    e.preventDefault();
    e.stopPropagation();

    var is_mobile_view = $('#upcoming-bookings-mob').is(":visible");
    var stats_url = $('.dashb-statistics')[0].href;
    if(is_mobile_view){
      stats_url = stats_url.replace('-desk', '');
    }
    window.location.href = stats_url;
  })

  // bind login form
  if($('#login-header-form').length > 0) {
    $('#login-header-form')
    .bind('ajax:success', function() {
      // redirect to /dashboard on login success
      window.location.href = "/dashboard/upcoming";
    })
    .bind("ajax:error", function(jqXHR, textStatus, errorThrown) {
      $('.login-errors').text(jqXHR.detail[0].error);
    });
  }

  if($('.percent').length > 0) {
    $('.percent').percentageLoader({
      valElement: 'p',
      strokeWidth: 10,
      bgColor: '#D3D3D3',
      ringColor: '#01bca1',
      textColor: '#000',
      fontSize: '18px',
      fontWeight: 'bold'
    });
  }

  $(".party__invites-content .nav-tabs a[data-toggle='tab']").click(function(e) {
    $(this).parents("li[role='presentation']").siblings("li").removeClass("active");
    $(this).parents("li[role='presentation']").addClass("active");
  });
});
