$(function(){
  $.ajax({
    url: '/trainers_and_categories',
    method: 'GET',
    dataType: 'script',
  });

  $('#home_trainers, .trainer-slider').on('click', '.trainer-content', function(e) {
    e.preventDefault();

    $('.social-links').show();
    $('.giveup-btn').show();
    $('.trainer__popup-wrap .left-section .social-links .fb_link').show();
    $('.trainer__popup-wrap .left-section .social-links .insta_link').show();
    $('.popup-wrap').removeClass('category__popup-wrap');

    var trainer_content =  "";
    var trainer_name = $(this).find('.trainer-name').text();
    var trainer_image = $(this).find('.trainer-pic img').attr('src');
    var trainer_image_alt = $(this).find('.trainer-pic img').attr('alt');
    var trainer_fb_link = $(this).find('.social-links .fb_link a').attr('href');
    var trainer_fb_link = $(this).find('.description').data().fbLink;
    var trainer_insta_link = $(this).find('.description').data().instaLink;
    var trainer_video_link = $(this).find('.description').data().videoLink;
    var trainer_video_text = $(this).find('.description').data().videoText;
    trainer_content = $(this).find('.description').html();
    $('.trainer__popup-wrap .left-section .inner-content .description_popup').html('');
    $('.trainer__popup-wrap .left-section .inner-content h3').text(trainer_name);
    $('.trainer__popup-wrap .right-section img').attr('src', trainer_image);
    $('.trainer__popup-wrap .right-section img').attr('alt', trainer_image_alt);
    $('.trainer__popup-wrap .left-section .inner-content .description_popup').html(trainer_content);
    if(trainer_insta_link)
      $('.trainer__popup-wrap .left-section .social-links .insta_link a').attr('href', trainer_insta_link);
    else
      $('.trainer__popup-wrap .left-section .social-links .insta_link').hide();
    if(trainer_fb_link)
      $('.trainer__popup-wrap .left-section .social-links .fb_link a').attr('href', trainer_fb_link);
    else
      $('.trainer__popup-wrap .left-section .social-links .fb_link').hide();
    if(trainer_video_link){
      $('.trainer__popup-wrap .left-section .giveup-btn a').text(trainer_video_text);
      $('.trainer__popup-wrap .left-section .giveup-btn a').attr('href', trainer_video_link);
    }
    else {
      $('.trainer__popup-wrap .left-section .giveup-btn').hide();
    }

    $('body').addClass('popup_enable');
    $('.trainer__popup-wrap').show();
  });

  $('.trainer__popup-wrap .close_icon').on('click', function(e){
    e.preventDefault();
    $('body').removeClass('popup_enable');
    $('.trainer__popup-wrap').hide();
  });

  $('#home_categories, .category-slider').on('click', '.category-content', function(e) {
    e.preventDefault();
    $('.social-links').hide();
    $('.giveup-btn').hide();
    $('.trainer__popup-wrap .right-section img').attr('src', '');
    $('.popup-wrap').addClass('category__popup-wrap');

    var category_name = $(this).text();
    var category_image = $(this).data().imageLink;
    var category_desc = $(this).data().description;

    $('.trainer__popup-wrap .left-section .inner-content .description_popup').html('');
    $('.trainer__popup-wrap .left-section .inner-content h3').text(category_name);
    $('.trainer__popup-wrap .right-section img').attr('src', category_image);
    $('.trainer__popup-wrap .right-section img').attr('alt', category_name);
    $('.trainer__popup-wrap .left-section .inner-content .description_popup').html(category_desc);

    $('body').addClass('popup_enable');
    $('.category__popup-wrap').show();
  });

  $(".statistics-content .nav-tabs").on('click', "a[data-toggle='tab']", function(e) {
    e.stopPropagation();
    e.preventDefault();

    var thisHref = $(this).attr("href");
    $(this).parents(".tab").find(".dropdown-menu").removeClass("show");

    if( $(this).parents("ul").hasClass("dropdown-menu") ) {
      $(this).parents(".dropdown-menu").removeClass("show");
      var thisText = $(this).text();
      $(this).parents(".dropdown").find(".dropdown-toggle").text(thisText);
    }

    $(this).parents("li[role='presentation']").siblings("li").removeClass("active");
    $(this).parents("li[role='presentation']").addClass("active");
    $(".statistics-content .tab-pane").removeClass("active").hide();
    $(".statistics-content .tab-pane"+thisHref).addClass("active").show();
  });

  $(".statistics-content").on('click', '.stats-dropdown li', function(e) {
    e.stopPropagation();
    e.preventDefault();

    if( $(this).parents("ul").hasClass("dropdown-menu") ) {
      $(this).parents(".dropdown-menu").removeClass("show");
      var thisText = $(this).text();
      $(this).parents(".dropdown").find(".dropdown-toggle").text(thisText);
    }

    $(this).parents("li[role='presentation']").siblings("li").removeClass("active");
    $(this).parents("li[role='presentation']").addClass("active");
    $(".statistics-content .tab-pane").removeClass("active").hide();
    $(".statistics-content .tab-pane#Statistikk").addClass("active").show();

    $.ajax({
      url: 'dashboard/fetch_stats',
      method: 'GET',
      data: { year: $(this).data('year') },
      success: function() {
        drawGraph();
      }
    });
  });
});
